import { lazy } from 'react';
import NotFound from './pages/NotFound/NotFound';
const ClubDetails = lazy(() => import('./pages/clubDetails/clubDetails'));
const routes = [
    {
        path: "/:id",
        component: <ClubDetails />,
        exact: true
    },
    {
        path: '*',
        component: <NotFound />,
        exact: true
    }
]

export default routes;