import React, { Suspense } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import routes from "./routes";
import './App.css';

function App() {
  return (
    <div className="App">
      <Suspense fallback=''>
        <BrowserRouter>
          <Routes>
            {routes.map((route, index) => {
              return <Route
                key={index}
                exact={route.exact}
                path={route.path}
                element={route.component}

              />
            })}
          </Routes>
        </BrowserRouter>
      </Suspense>
    </div>
  );
}

export default App;
